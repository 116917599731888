import React, {useRef} from 'react';
import './NextPost.scss';
import clsx from 'clsx';
import { Link } from 'gatsby';
import LeftRight from '../../assets/arrow-right.svg';

import CaseStudy from '../../sections/CaseStudy/CaseStudy';
import fadeInElementAnimation from "../../resources/fade-in-element-animation";

const NextPost = ({ post, category }) => {
    const nextArticleRef = useRef(null);
    fadeInElementAnimation([nextArticleRef], '90')
    return (
        <div ref={nextArticleRef} className={clsx('next-post')}>
            <div className="spacer"/>
            <div className="next-post__header">
                <h4>NASTĘPNY ARTYKUŁ</h4>
                <a className="wrap">
                    <img src={LeftRight}/>
                </a>

            </div>
            <Link to={`/${category}/${post.slug}`}>
                <CaseStudy additionalClasses={['case-study-section']} caseStudies={[post]}/>
            </Link>
            <div className="spacer"/>

        </div>
    )
};

export default NextPost;
