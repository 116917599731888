import * as React from 'react';
import Slider from 'react-slick';
import './Gallery.scss';
import {useRef} from "react";
import fadeInElementAnimation from "../../resources/fade-in-element-animation";

const Gallery = ({ images }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        centerMode: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '20%',
        swipe: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    focusOnSelect: true,
                    arrows: false,
                    swipe: true,
                },
            },
        ],
    };
    const galleryRef = useRef(null);
    fadeInElementAnimation([galleryRef]);
    return (
        <div ref={galleryRef} className="gallery-component">
            <Slider {...settings}>
                {images.map((image) => (
                    <img
                        src={image.localFile.publicURL}
                        alt=""
                        className="gallery-component__image"
                        key={image.localFile.publicURL}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default Gallery;
