import * as React from 'react';
import { graphql } from 'gatsby';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import YouTube from 'react-youtube';
import Navbar from '../components/Navbar/Navbar';
import ScrollTop from '../components/ScrollTop/ScrollTop';
import Footer from '../sections/Footer/Footer';
import HeroText from '../sections/HeroText/HeroText';
import Gallery from '../components/Gallery/Gallery';
import PlayButton from '../assets/play-button.svg';
import HeroSlideup from '../sections/HeroSlideup/HeroSlideup';
import entryAnimation from '../resources/entry-animation';
import NextPost from '../components/NextPost/NextPost';
import fadeInElementAnimation from '../resources/fade-in-element-animation';

const CaseStudyTemplate = (props) => {
    const navbarBreakpointRef = useRef();
    const data = props.data.posts;
    const date = new Date(data.flotiqInternal.createdAt);
    const { previous } = props.pageContext;
    const padStart = (value) => (value < 10 ? `0${value}` : `${value}`);
    const mainRef = useRef(null);
    entryAnimation(mainRef, data.image[0]);

    let categorySlug = '';
    switch (data.category) {
    case 'Case study':
    default:
        categorySlug = 'case-study';
        break;
    case 'Media o nas':
        categorySlug = 'media-o-nas';
        break;
    case 'Blog':
        categorySlug = 'blog';
        break;
    }

    const playButton = useRef();
    const [video, setVideo] = useState();

    const play = () => {
        video.playVideo();
    };

    const onStateChange = (event) => {
        if (event.data === 1) {
            playButton.current.style.width = 0;
            playButton.current.style.opacity = 0;
        } else {
            playButton.current.style.width = '100px';
            playButton.current.style.opacity = 1;
        }
    };

    const brandLogoRef = useRef(null);
    const topSectionRef = useRef(null);
    const subHeaderRef = useRef(null);
    const firstCategoryRef = useRef(null);
    const vidRef = useRef(null);
    const secondCategoryRef = useRef(null);
    const secondVidRef = useRef(null);
    const angledTextRef = useRef(null);
    const thirdCategoryRef = useRef(null);
    const galleryHeaderRef = useRef(null);
    const galleryDescRef = useRef(null);
    fadeInElementAnimation([brandLogoRef, topSectionRef,
        subHeaderRef, firstCategoryRef, vidRef,
        secondCategoryRef, secondVidRef, angledTextRef,
        thirdCategoryRef, galleryHeaderRef, galleryDescRef], '90');

    return (
        <main ref={mainRef} className="case-study-page">
            <div ref={navbarBreakpointRef} className="ref-navbar" />
            <Navbar
                breakpointRef={navbarBreakpointRef}
            />
            <Helmet>
                <title>GetHero</title>
            </Helmet>
            {data.image[0] ? (
                <HeroSlideup
                    header={`#${data.name}`}
                    image={data.image[0].localFile.publicURL}
                />
            ) : (
                <HeroText header={`#${data.name}`} />
            )}

            <div ref={brandLogoRef} className="case-study-page__brand-logo">
                {data.brand_logo
                && data.brand_logo[0]
                && <img src={data.brand_logo[0].localFile.publicURL} alt={data.name} />}
            </div>
            <div ref={topSectionRef} className="case-study-page__top-section">
                <div>
                    {padStart(date.getDate())}
                    .
                    {padStart(date.getMonth() + 1)}
                    .
                    {date.getFullYear()}
                </div>
                <div>
                    #
                    {data.hashtag}
                </div>
            </div>
            <div className="case-study-page__main-section">
                { data.subheader && (
                    <div className="row case-study-page__section">
                        <div className="col-sm-2 d-none d-md-block d-lg-block">
                            {/* UDOSTĘPNIJ */}
                            <div />
                        </div>
                        <div
                            ref={subHeaderRef}
                            className="col-sm-10 case-study-page__subheader case-study-page__subheader--bordered"
                        >
                            {data.subheader}
                        </div>
                    </div>
                )}
                {(data.first_category || data.first_part) && (
                    <div ref={firstCategoryRef} className="row case-study-page__section">
                        <div className="col-sm-2 mb-5">
                            {data.first_category}
                        </div>
                        <div className="col-sm-10">
                            <div dangerouslySetInnerHTML={{ __html: data.first_part.replace('f1c40f', 'FECD32') }} />
                        </div>
                    </div>
                )}
                <div className={data.first_imagevideo_full_width
                    ? 'case-study-page__section' : 'row case-study-page__section'}
                >
                    {data.first_video_id
                        ? (
                            <div
                                ref={vidRef}
                                className={data.first_imagevideo_full_width
                                    ? 'full-width' : 'col-sm-10 offset-sm-2'}
                            >
                                <img
                                    src={PlayButton}
                                    alt="Play"
                                    className="video__play-button"
                                    onClick={play}
                                    ref={playButton}
                                />
                                <YouTube
                                    videoId={data.first_video_id}
                                    containerClassName="video-wrapper"
                                    onReady={(event) => {
                                        setVideo(event.target);
                                    }}
                                    onStateChange={(event) => {
                                        onStateChange(event);
                                    }}
                                    opts={{
                                        width: '100%',
                                        height: '100%',
                                        playerVars: {
                                            autoplay: 0,
                                            controls: 0,
                                            modestbranding: 1,
                                            showinfo: 0,
                                            mute: 0,
                                            rel: 0,
                                        },
                                    }}
                                />
                            </div>
                        )
                        : (
                            <div
                                ref={vidRef}
                                className={data.first_imagevideo_full_width
                                    ? 'full-width' : 'col-sm-10 offset-sm-2'}
                            >
                                {data.first_image && data.first_image[0] && (
                                    <img
                                        src={data.first_image[0].localFile.publicURL}
                                        alt={data.name}
                                        className={data.first_imagevideo_full_width ? 'full-width' : 'img-fluid'}
                                    />
                                )}
                            </div>
                        )}
                </div>
                {(data.second_category || data.second_part) && (
                    <div ref={secondCategoryRef} className="row case-study-page__section">
                        <div className="col-sm-2 mb-5">
                            {data.second_category}
                        </div>
                        <div className="col-sm-10">
                            <div dangerouslySetInnerHTML={{ __html: data.second_part.replace('f1c40f', 'FECD32') }} />
                        </div>
                    </div>
                )}
                {data.second_video_id && (
                    <div
                        ref={secondVidRef}
                        className={data.second_imagevideo_full_width ? '' : 'row case-study-page__section'}
                    >
                        {data.second_video_id
                            ? (
                                <div className={data.second_imagevideo_full_width
                                    ? 'full-width' : 'col-sm-10 offset-sm-2'}
                                >
                                    <img
                                        src={PlayButton}
                                        alt="Play"
                                        className="video__play-button"
                                        onClick={play}
                                        ref={playButton}
                                    />
                                    <YouTube
                                        videoId={data.second_video_id}
                                        containerClassName="video-wrapper"
                                        onReady={(event) => {
                                            setVideo(event.target);
                                        }}
                                        onStateChange={(event) => {
                                            onStateChange(event);
                                        }}
                                        opts={{
                                            width: '100%',
                                            height: '100%',
                                            playerVars: {
                                                autoplay: 0,
                                                controls: 0,
                                                modestbranding: 1,
                                                showinfo: 0,
                                                mute: 0,
                                                rel: 0,
                                            },
                                        }}
                                    />
                                </div>
                            )
                            : (
                                <div className={data.second_imagevideo_full_width
                                    ? 'full-width' : 'col-sm-10 offset-sm-2'}
                                >
                                    {data.second_image && data.second_image[0] && (
                                        <img
                                            src={data.second_image[0].localFile.publicURL}
                                            alt={data.name}
                                            className={data.second_imagevideo_full_width ? 'full-width' : 'img-fluid'}
                                        />
                                    )}
                                </div>
                            )}
                    </div>
                )}
                {data.angled_texts && (
                    <div ref={angledTextRef} className="row">
                        <div className="col-sm-10 offset-sm-2 case-study-page__angled-texts">
                            {data.angled_texts.map((info, index) => (
                                <div
                                    dangerouslySetInnerHTML={{ __html: info.text.replace('f1c40f', 'FECD32') }}
                                    key={index}
                                    className="case-study-page__angled-texts__text"
                                />
                            ))}
                        </div>
                    </div>
                )}
                { (data.third_category || data.third_part) && (
                    <div ref={thirdCategoryRef} className="row case-study-page__section">
                        <div className="col-sm-2 mb-5">
                            {data.third_category}
                        </div>
                        <div className="col-sm-10">
                            <div dangerouslySetInnerHTML={{ __html: data.third_part.replace('f1c40f', 'FECD32') }} />
                        </div>
                    </div>
                )}
                { data.gallery_header && (
                    <div ref={galleryHeaderRef} className="row case-study-page__section">
                        <div className="col-sm-10 offset-sm-2 case-study-page__subheader">{data.gallery_header}</div>
                    </div>
                )}
                {data.gallery && data.gallery[0] && (
                    <div className="case-study-page__section full-width-only">
                        <Gallery images={data.gallery} />
                    </div>
                )}
                {data.gallery_description && (
                    <div ref={galleryDescRef} className="row case-study-page__section">
                        <div className="col-sm-10 offset-sm-2">
                            <div
                                dangerouslySetInnerHTML={
                                    { __html: data.gallery_description.replace('f1c40f', 'FECD32') }
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
            {previous && (<NextPost post={previous} category={categorySlug} />)}
            <div>
                <Footer />
            </div>
            <ScrollTop />
        </main>
    );
};

export const pageQuery = graphql`
    query CaseStudySettings($slug: String!, $category: String!) {
      posts(slug: {eq: $slug}, category: {eq: $category}) {
        category
        brand_logo {
          localFile {
            publicURL
          }
        }
        angled_texts {
          text
        }
        description
        first_category
        first_image {
          localFile {
            publicURL
          }
        }
        first_imagevideo_full_width
        first_part
        first_video_id
        gallery {
          localFile {
            publicURL
          }
        }
        gallery_description
        gallery_header
        hashtag
        image {
          localFile {
            publicURL
          }
        }
        name
        second_category
        second_imagevideo_full_width
        second_part
        second_video_id
        show_on_main_page
        slug
        subheader
        third_category
        third_part
        second_image {
          localFile {
            publicURL
          }
        }
        flotiqInternal {
          createdAt
        }
      }
    }`;

export default CaseStudyTemplate;
